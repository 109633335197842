<template>
  <div class="locations">
    <AppSpinnerBig :loading="isIframeLoading" />
    <iframe :src="iframeSrc" allow="geolocation" @load="onIframeLoaded"></iframe>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useIframeLoader } from '@galileo/composables/useIframeLoader'
import { useDashboardView } from '@galileo/composables/useDashboardView'
import { AppSpinnerBig } from '@oen.web.vue2/ui'
import { useAppStore } from '@galileo/stores'

export default {
  name: 'Locations',
  components: {
    AppSpinnerBig,
  },
  setup() {
    const appStore = useAppStore()
    const { isIframeLoading, onIframeLoaded } = useIframeLoader()
    const { mounted, unmounted } = useDashboardView()

    const iframeSrc = computed(() => appStore.getXeAppUrl('locations'))
    mounted()
    unmounted()

    return {
      isIframeLoading,
      onIframeLoaded,
      iframeSrc,
    }
  },
}
</script>

<style scoped>
.locations {
  @apply h-full w-full;

  iframe {
    @apply absolute w-full;
    @apply z-40;
    margin-top: -66px;
    height: calc(100% + 65px - 56px);

    @screen md {
      width: calc(100% - 256px);
      height: calc(100% + 65px);
    }
  }
}
</style>
